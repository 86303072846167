// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-rae-copy-js": () => import("./../src/pages/rae copy.js" /* webpackChunkName: "component---src-pages-rae-copy-js" */),
  "component---src-pages-rechtsanwaelte-js": () => import("./../src/pages/rechtsanwaelte.js" /* webpackChunkName: "component---src-pages-rechtsanwaelte-js" */),
  "component---src-pages-rechtsgebiete-js": () => import("./../src/pages/rechtsgebiete.js" /* webpackChunkName: "component---src-pages-rechtsgebiete-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-rechtsgebiet-js": () => import("./../src/templates/rechtsgebiet.js" /* webpackChunkName: "component---src-templates-rechtsgebiet-js" */)
}

