// Theme
// https://theme-ui.com/theming

const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
  letterSpacing: "normal",
};

const body = {
  color: "text",
  fontFamily: "body",
  lineHeight: "body",
  fontWeight: "body",
};

const spacer = {
  margin: "0 auto",
  // maxWidth: 1200,
  py: 4,
  px: 4,
};

export default {
  space: [0, 8, 16, 48, 144, 216],
  sizes: {
    container: 880,
    pagesidebar: (880 / 5) * 1.5,
  },
  fonts: {
    // Font --> Google Fonts --> "Montserrat"
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: '"PT Serif", serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    9, // 0
    12, // 1
    16, // 2
    20, // 3
    24, // 4
    32, // 5
  ],
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacing: {
    small: ".025em",
    big: "0.1em",
  },
  colors: {
    text: "hsl(0, 0%, 0%)",
    background: "hsl(0, 0%, 100%)",
    // primary: 'hsl(45, 55%, 50%)',
    primary: "hsl(100, 100%, 20%)",
    secondary: "hsl(45, 55%, 50%)",
    muted: "hsl(0, 0%, 90%)",
    highlight: "hsl(200, 50%, 80%)",
    gray: "hsl(0, 0%, 50%)",
    accent: "#3030f2",
    darken: "#111",
    modes: {
      dark: {
        text: "hsl(0,0%,100%)",
        background: "hsl(0,0%,10%)",
        primary: "hsl(100, 100%, 30%)",
        secondary: "hsl(45, 55%, 50%)", // hsl(45, 55%, 50%)
        muted: "hsl(0, 0%, 30%)",
        highlight: "hsl(200, 50%, 30%)",
        gray: "hsl(0, 0%, 60%)",
        accent: "#3030f2",
        darken: "#111",
      },
    },
  },
  transition: {
    normal: ".3s ease",
  },
  styles: {
    root: {
      ...body,
      cursor: "default",
      fontSize: [1, 2],
      whiteSpace: "pre-line",
      "b, strong": {
        fontWeight: 700,
      },
      hr: {
        borderStyle: "none none solid none",
        borderWidth: "1px",
        borderColor: "muted",
      },
    },
    h1: {
      ...heading,
      fontSize: [4, 5, 5],
    },
    h2: {
      // ...heading,
      fontSize: [2, 3, 4],
    },
    h3: {
      // ...heading,
      // ...body,
      fontSize: [2, 2, 3],
      fontWeight: "heading",
    },
    h4: {
      // ...heading,
      fontSize: [2, 2, 3],
      margin: 0,
    },
    h5: {
      // ...heading,
      fontSize: [1],
      // letterSpacing: 'big'
    },
    h6: {
      // ...heading,
      fontSize: [1],
      // letterSpacing: 'big'
    },
    p: {
      ...body,
      margin: "0 auto",
      fontSize: [2],
      pt: [1, 2, 2],
      pb: [2, 3, 3],
      pl: [2, 2, 0],
      pr: [2, 3, 3],
      lineHeight: 1.8,
      letterSpacing: "small",
      columns: "300px 2",
      // ":first-letter": {
      //   float: "left",
      //   fontSize: ["56px"],
      //   lineHeight: 1,
      //   fontWeight: 500,
      //   margin: 0,
      //   marginRight: [1],
      // },
    },
    a: {
      color: "gray",
      transition: "normal",
      ":hover, :focus": {
        // color: 'secondary',
        // py: 2,
        borderBottom: "2px solid",
        borderColor: "primary",
        // opacity: 1,
      },
      textDecoration: "none",
      letterSpacing: "big",
      fontWeight: "heading",
      fontSize: [1, 1, 1],
      textTransform: "uppercase",
      borderBottom: "1px solid",
      py: 1,
      // opacity: 0.5,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    spacer: {
      ...spacer,
    },
  },
  buttons: {
    primary: {
      color: "background",
      bg: "primary",
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    gray: {
      color: "background",
      bg: "gray",
    },
  },
  links: {
    nav: {
      px: [1, 2, 3],
      py: [2, 2, 2],
      transition: "normal",
      textTransform: "uppercase",
      fontWeight: "heading",
      fontSize: [0, 1],
      letterSpacing: "big",
    },
  },
  shadow: {
    default: {
      boxShadow: "0px 10px 20px -10px rgba(0, 0, 0, 0.3)",
    },
  },
  text: {
    bigLetter: {
      ":first-letter": {
        float: "left",
        fontSize: ["56px"],
        lineHeight: 1,
        fontWeight: 500,
        margin: 0,
        marginRight: [1],
      },
    },
    markdown: {
      ">p:first-of-type": {
        margin: "0",
        // border: "1px solid red",
      },
    },
  },
};
